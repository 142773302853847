@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';


.media-img {
    height: 12.5rem;
    width: 12.5rem;
     border: 5px solid $white;
    background-color: $white;
    border-radius: $card-border-radius;
    box-shadow: $box-shadow;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .featured-img {
    height: 35px;
    width: 35px;
    border: 1px solid $white;
    box-shadow: $box-shadow;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }

  .border-dashed{
    border-style: dashed !important;
  }

  .flex-basis-auto{
    flex-basis: auto;
  }
  .avatar-rounded img{
    border-radius: 5px;
  }

  @media print {
    .no-print{
      display: none !important;
    }
  }